import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-51d6d014"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "branch-viewer",
  "aria-live": "polite"
}
const _hoisted_2 = {
  key: 0,
  id: "branch-viewer-wrapper"
}
const _hoisted_3 = { class: "branch-viewer-total level is-mobile" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "level is-mobile" }
const _hoisted_6 = { class: "branch-viewer-nav is-hidden-mobile level-right has-background-contents" }
const _hoisted_7 = { class: "branch-viewer-item-list" }
const _hoisted_8 = { class: "branch-viewer-pager" }
const _hoisted_9 = {
  key: 1,
  id: "no-child-item-msg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_IconLoading = _resolveComponent("IconLoading")!
  const _component_AppSelect = _resolveComponent("AppSelect")!
  const _component_ItemListView = _resolveComponent("ItemListView")!
  const _component_PageNavigation = _resolveComponent("PageNavigation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.itemList.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('label.allCase')) + " " + _toDisplayString(_ctx.$t('label.result' , { start: _ctx.toLocale(_ctx.start + 1, _ctx.lang), end: _ctx.toLocale(_ctx.end, _ctx.lang), total: _ctx.toLocale(_ctx.total, _ctx.lang) })), 1),
            _createElementVNode("span", _hoisted_5, [
              (!_ctx.isRekion && _ctx.isAllowedDownload)
                ? (_openBlock(), _createBlock(_component_AppButton, {
                    key: 0,
                    onClick: _ctx.downloadBranch,
                    onKeydown: _withKeys(_withModifiers(_ctx.downloadBranch, ["prevent"]), ["space","enter"]),
                    styled: "is-accent",
                    disabled: _ctx.downloadingFlag,
                    id: "download-branch-button"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('parts.bulkDownload')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick", "onKeydown", "disabled"]))
                : _createCommentVNode("", true),
              (_ctx.downloadingFlag)
                ? (_openBlock(), _createBlock(_component_IconLoading, {
                    key: 1,
                    class: "branch-download-icon-loading"
                  }))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["branch-viewer-body", {
          'list-view': _ctx.state.displayMode === 'list',
          'thumbnail-view': _ctx.state.displayMode === 'thumbnail'
        }])
          }, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_AppSelect, {
                modelValue: _ctx.state.displayMode,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.displayMode) = $event)),
                options: _ctx.displayModeLocale,
                componentId: "the-selectbox-display-mode"
              }, null, 8, ["modelValue", "options"]),
              _createVNode(_component_AppSelect, {
                componentId: "the-selectbox-page-size",
                modelValue: _ctx.state.displayedItemCount,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.displayedItemCount) = $event)),
                options: _ctx.branchDisplayLocale
              }, null, 8, ["modelValue", "options"])
            ]),
            _createElementVNode("div", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.itemList, (item) => {
                return (_openBlock(), _createBlock(_component_ItemListView, {
                  item: item,
                  key: item.id,
                  tag: "article",
                  class: "branch-viewer-item-article",
                  "display-mode": _ctx.state.displayMode,
                  "has-snippet": false
                }, null, 8, ["item", "display-mode"]))
              }), 128))
            ])
          ], 2),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_PageNavigation, {
              modelValue: _ctx.state.pageNumber,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.pageNumber) = $event)),
              displayCount: 5,
              items: _ctx.pageNavigationIndex
            }, null, 8, ["modelValue", "items"])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('branchViewer.notFound')), 1)
        ]))
  ]))
}